<template>
	<div class="container lg:mx-auto lg:flex lg:items-end lg:justify-center">
		<header class="flex items-center justify-between mx-4 py-6 lg:hidden">
			<img src="/images/logo.svg" alt="logo" />
			<button @click="() => (isOpenMenu = true)" title="Abrir menu">
				<Menu class="text-white w-9 h-9" />
			</button>
		</header>

		<div class="rounded-t-2xl px-4 lg:px-0 lg:mt-8 mb-16">
			<section class="pb-8">
				<div class="relative">
					<div class="h-1/2 w-full absolute -bottom-8 bg-[#45531E] rounded-2xl -z-10"></div>
					<div class="h-1/2 w-full absolute -bottom-4 bg-[#859D44] rounded-2xl -z-10"></div>

					<div
						class="py-16 lg:py-11 rounded-2xl relative z-20"
						style="background-image: url(/images/mask-bg-hero.jpg)"
					>
						<HeaderDesktop :items="headerItems" class="mx-28" />

						<div class="lg:grid lg:grid-cols-2 lg:mx-28 lg:gap-8 mt-8">
							<div class="lg:flex lg:flex-col lg:justify-center">
								<h1 class="font-extrabold text-3xl font-jakarta text-center lg:text-start lg:text-5xl">
									Apoie seu <br />
									streamer favorito.
								</h1>

								<p class="font-inter my-6 w-4/5 mx-auto text-center lg:text-start lg:mx-0 lg:text-lg">
									Mostre seu amor e apoio, esteja presente para aqueles que você mais valoriza.
								</p>

								<Button
									href="/streamers"
									class="w-9/12 lg:w-fit mx-auto lg:mx-0 lg:px-24 hover:bg-black hover:text-white"
									title="Comprar"
									>Comprar</Button
								>
							</div>

							<LandingPageVideoCard
								class="mt-8 lg:mt-0"
								:autoplay="true"
								videoSrc="https://cdn.streamersclub.com.br/assets/videos/unboxing/nimayumi.mp4"
								thumbnailSrc="https://cdn.streamersclub.com.br/assets/videos/thumbnails/nimayumi.jpg"
							/>
						</div>
					</div>
				</div>
			</section>

			<section class="!mt-8 !lg:mt-11" id="about">
				<div class="py-20 bg-white rounded-2xl text-center">
					<p class="bg-[#F2F2F2] rounded-2xl p-3 w-fit font-bold text-base mx-auto">Sobre</p>

					<h1 class="font-jakarta font-extrabold text-3xl py-6 lg:text-6xl">
						O poder <br class="hidden md:block" />
						da comunidade.
					</h1>

					<p class="w-full px-4 mb-12 text-base lg:text-2xl">
						Transforme apoio em conexão: celebre seu streamer
						<br class="hidden lg:block" />
						favorito com uma caixa!
					</p>

					<div class="mx-4 grid grid-cols-1 gap-6 lg:gap-8 lg:mx-28 md:grid-cols-2 lg:grid-cols-3">
						<div
							class="border border-[#D8E3FF] py-6 rounded-2xl"
							v-for="item in aboutItems"
							:key="item.title"
							:class="item.class || ''"
						>
							<component class="mx-auto" :is="item.icon" />
							<h1 class="font-semibold text-lg mt-6 mb-4">
								{{ item.title }}
							</h1>
							<p class="text-sm text-[#18181B]/80 mx-6">
								{{ item.description }}
							</p>
						</div>
					</div>

					<template v-if="highlightedStreamer">
						<div class="grid grid-template grid-cols-1 lg:grid-cols-2 gap-8 mt-16 lg:mx-28">
							<div class="lg:flex lg:flex-col lg:justify-center col1">
								<h1 class="font-jakarta font-extrabold text-3xl text-center lg:text-start lg:text-5xl">
									Vem dar<br />
									uma olhadinha!
								</h1>

								<p class="w-full px-4 mt-6 lg:text-start lg:p-0 lg:text-2xl">
									Veja a demonstração da caixa do(a) influenciador(a)
									<NuxtLink
										:to="`/streamers/${highlightedStreamer?.slug}`"
										class="hover:text-[#CEE474] font-bold"
										>{{ highlightedStreamer?.displayName }}</NuxtLink
									>.
								</p>

								<Button
									variant="black"
									class="w-fit px-5 text-sm gap-2 items-center mx-auto hidden mt-6 lg:flex lg:mr-auto lg:ml-0"
									:href="'/streamers/' + highlightedStreamer?.slug"
									title="Também quero!"
								>
									<span>Também quero!</span>
									<IconsLovelyFace class="hover:fill-[#CEF26F]" />
								</Button>
							</div>

							<LandingPageVideoCard
								class="col2"
								videoSrc="https://cdn.streamersclub.com.br/assets/videos/unboxing/nimayumi.mp4"
								thumbnailSrc="https://cdn.streamersclub.com.br/assets/videos/thumbnails/nimayumi.jpg"
							/>
						</div>
						<div class="mt-8 lg:hidden">
							<Button
								variant="black"
								class="w-fit px-5 text-sm flex gap-2 items-center mx-auto"
								:href="'/streamers/' + highlightedStreamer?.slug"
								title="Também quero!"
							>
								<span>Também quero!</span>
								<IconsLovelyFace />
							</Button>
						</div>
					</template>
				</div>
			</section>

			<section class="!mt-8 !lg:mt-11" id="service">
				<div class="lg:px-28 bg-[#141808] relative px-4 py-20 rounded-2xl">
					<img
						:src="`/images/landing-page/light-effect-bg${device.isMobile ? '-mobile' : ''}.png`"
						alt="light effect"
						class="pointer-events-none rounded-2xl absolute top-0 left-0 w-full h-fit object-cover"
					/>
					<p class="bg-[#F2F2F2] rounded-2xl p-3 w-fit font-bold text-base mx-auto">Serviço</p>

					<h1
						class="font-jakarta font-extrabold text-3xl py-6 text-white text-center lg:text-6xl lg:font-bold"
					>
						Abriu? <br />
						Descobriu!
					</h1>

					<p class="w-3/4 mx-auto text-white mb-8 text-center lg:text-2xl">
						Descubra os produtos personalizados
						<br class="hidden lg:block" />
						de seu streamer favorito.
					</p>

					<div class="grid gap-6 grid-cols-12 lg:gap-x-8 lg:gap-y-10">
						<IconsLandingPageRiskIcon class="col-span-12 hidden lg:block" />
						<div class="col-span-12 lg:col-span-5 h-full relative">
							<div class="flex justify-between items-center mb-3 lg:hidden">
								<IconsLandingPageRiskIcon />
								<IconsLandingPageHeartIcon />
							</div>

							<img
								class="rounded-2xl w-full lg:h-full"
								src="/images/banner-01.jpg"
								alt="streamers club banner"
							/>
						</div>

						<div class="col-span-12 lg:col-span-7 lg:flex lg:flex-col">
							<div>
								<IconsLandingPageHeartIcon class="hidden lg:block mb-6" />
								<div class="bg-[#272F13] w-full rounded-2xl">
									<p
										class="flex items-center text-[#CEE474] font-bold text-xl py-4 justify-center lg:text-2xl"
									>
										Streamersclub:
										<img
											v-for="(_, i) in device.isMobile ? 4 : 10"
											src="/images/twitch-emoji.png"
											:class="{ 'ml-2': !i }"
											alt="twitch emoji"
										/>
									</p>
								</div>
							</div>

							<div
								class="flex-1 bg-[#272F13] w-full rounded-2xl py-10 px-4 mt-6 flex justify-between flex-col"
							>
								<h1 class="text-white font-jakarta font-bold text-3xl mb-20 lg:mb-0">
									Decore, colecione, use.
								</h1>

								<p class="text-white/80 text-lg lg:text-xl">
									Com esses produtos, você poderá usá-los no seu dia a dia e ainda receberá giftcards
									para usar como preferir.
								</p>
							</div>
						</div>

						<div
							class="col-span-12 bg-[#272F13] w-full rounded-2xl py-6 px-4 lg:col-span-12 lg:flex lg:justify-between lg:items-center"
						>
							<p class="text-base font-jakarta text-white lg:text-lg">
								<span class="text-[#CEF26F] font-bold">Criado com amor</span>
								para os entusiastas de produtos personalizados.
							</p>

							<Button
								href="/streamers"
								class="mt-6 lg:mt-0 text-sm w-full md:w-fit px-7"
								title="Escolher Streamer"
								>Escolher Streamer</Button
							>
						</div>

						<div class="grid grid-cols-1 gap-6 lg:grid-cols-3 col-span-12">
							<BoxCard
								v-for="box in sortedBoxes"
								:key="box._id"
								:box="box"
								@buyClick="navigateTo('/streamers')"
							/>
						</div>
					</div>
				</div>
			</section>

			<section class="!mt-8 !lg:mt-11">
				<div
					class="py-20 overflow-hidden px-4 lg:px-28 rounded-2xl max-h-[800px] lg:max-h-[1000px] bg-no-repeat bg-center bg-cover relative"
					style="background-image: url(/images/landing-page/opinion-bg.png)"
				>
					<div
						class="absolute w-full bottom-0 -mb-8 rounded-b-2xl left-0 bg-[linear-gradient(180deg,_rgba(35,_40,_21,_0)_0%,_#232815_70%)] h-1/3 z-10"
					></div>

					<p class="bg-[#F2F2F2] rounded-2xl p-3 w-fit font-bold text-base mx-auto">Opinião</p>

					<p class="font-extrabold text-3xl font-jakarta text-white text-center mt-6 lg:mt-4 lg:text-6xl">
						O que nossos <br />
						clientes dizem
					</p>

					<div
						class="mt-8 rounded-t-2xl overflow-y-auto flex flex-col scroll-smooth h-[600px] pointer-events-none hide-scrollbar"
						ref="containerRef"
					>
						<div class="space-y-6 scroll-content">
							<div
								class="bg-[#212121]/90 w-full rounded-2xl lg:w-fit"
								v-for="item in fakeChatMessages"
								:key="item.id"
							>
								<div
									class="md:flex md:items-center py-4 px-6 md:justify-center"
									:style="{
										color: item.color,
									}"
								>
									<div class="mb-3 md:mb-0 flex items-center gap-3 self-start">
										<IconsLandingPageHeartIcon class="w-6 h-6" />
										<p class="font-bold text-2xl lg:text-xl">{{ item.nickname }}:</p>
									</div>
									<span class="md:ml-2 text-lg text-white font-medium">{{ item.message }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="!mt-8 px lg:px-0" id="partners">
				<!-- <p
					v-if="streamers?.results.length"
					class="bg-[#F2F2F2] rounded-2xl p-3 w-fit font-bold text-base mx-auto"
				>
					Parceiros
				</p>

				<h1
					v-if="streamers?.results.length"
					class="text-white text-center mt-6 mb-10 font-extrabold text-3xl font-jakarta lg:text-6xl"
				>
					Streamers que<br class="hidden md:block" />
					estão participando.
				</h1>

				<div v-if="streamers?.results.length" class="relative flex overflow-x-hidden max-w-[1200px]">
					<div
						class="bg-gradient-to-r from-[#050607] to-transparent w-1/3 absolute -left-1 top-0 h-full z-20 pointer-events-none"
					></div>

					<div
						class="bg-gradient-to-l from-[#050607] to-transparent w-1/3 absolute -right-1 top-0 h-full z-20 pointer-events-none"
					></div>

					<div class="animate-marquee whitespace-nowrap flex">
						<LandingPageMarqueeStreamerCard :streamer="streamer" v-for="streamer in marqueeStreamers" />
					</div>

					<div class="absolute top-0 animate-marquee2 whitespace-nowrap flex">
						<LandingPageMarqueeStreamerCard :streamer="streamer" v-for="streamer in marqueeStreamers" />
					</div>
				</div> -->

				<FaqAccordion accordionItemClass="border-b-zinc-900" class="lg:mt-28 mt-16" />

				<div class="grid grid-cols-1 lg:grid-cols-2 mt-16 lg:mt-28 lg:gap-8">
					<div>
						<h1
							class="text-white text-center mb-6 font-extrabold text-3xl font-jakarta lg:text-6xl lg:text-start"
						>
							Deseja participar?
						</h1>

						<p class="text-center text-base text-white lg:text-lg lg:text-start">
							Comece agora a oferecer caixas personalizadas para a sua comunidade e aumente o engajamento,
							além de gerar uma nova fonte de renda.
						</p>
					</div>

					<form @submit.prevent="onSubmit" class="mt-8 space-y-6 lg:mt-0">
						<InputWithIcon
							labelClass="mb-2"
							label="Qual a twitch?"
							placeholder="https://www.twitch.tv/..."
							minlength="4"
							maxlength="25"
							v-model="streamer.twitch"
						>
							<template v-slot:icon class="">
								<Twitch :size="20" color="#6C7275" />
							</template>
						</InputWithIcon>
						<span
							v-if="errors?.twitch?.length && streamer.twitch.length"
							class="is-error text-red-400 text-sm mt-1"
							>{{ errors.twitch[0].message }}</span
						>

						<InputWithIcon
							inputmode="numeric"
							mask="(##) #####-####"
							labelClass="mb-2"
							label="Seu whatsapp"
							placeholder="Insira seu número com DDD"
							v-model="streamer.phone"
						>
							<template v-slot:icon class="">
								<Phone :size="20" color="#6C7275" />
							</template>
						</InputWithIcon>
						<span
							v-if="errors?.phone?.length && streamer.phone.length"
							class="is-error text-red-400 text-sm mt-1"
							>{{ errors.phone[0].message }}</span
						>

						<Button class="rounded-xl mt-6" type="submit" :disabled="!isValid" :loading="loading.streamer"
							>Participar do StreamersClub</Button
						>
					</form>
				</div>
			</section>
		</div>
	</div>

	<HeaderMobile
		:showControls="false"
		@onToggle="value => (isOpenMenu = value)"
		:is-open="isOpenMenu"
		:items="headerItems"
	/>
</template>

<script setup lang="ts">
import IconsAboutSectionOne from '~/components/icons/about-section/One.vue'
import IconsAboutSectionTwo from '~/components/icons/about-section/Two.vue'
import IconsAboutSectionThree from '~/components/icons/about-section/Three.vue'

import { Menu, Phone, Twitch } from 'lucide-vue-next'
import { faker } from '@faker-js/faker'
import type { IUser } from '~/types/models/user.interface'
import type { IBox } from '~/types/models/box.interface'
import type { IPagination } from '~/types/pagination.interface'
import { toast } from 'vue-sonner'
import { z } from 'zod'

interface Streamer extends IUser {
	boxes: (IBox & { price: number })[]
}

const streamer = ref({
	twitch: '',
	phone: '',
})
const { $api } = useNuxtApp()
const containerRef = ref<HTMLDivElement | null>(null)
const interval = ref<any>(null)
const streamers = ref<IPagination<Streamer> | undefined>(undefined)
const boxes = ref<IBox[]>([])
const isOpenMenu = ref(false)
const aboutItems = [
	{
		icon: IconsAboutSectionOne,
		title: 'Lembrança',
		description: 'Leve consigo um pedaço do universo do seu streamer favorito com itens personalizados e únicos.',
	},
	{
		icon: IconsAboutSectionTwo,
		title: 'Compartilhe',
		description: 'Compartilhe nas redes sociais marcando seu streamer.',
	},
	{
		icon: IconsAboutSectionThree,
		title: 'Presente Perfeito',
		description: 'Surpreenda quem você ama com um presente pensado para criar momentos inesquecíveis',
		class: 'md:col-span-2 lg:col-span-1',
	},
]
const { device } = useDeviceStore()
const fakeChatMessages = ref<any[]>([])
const loading = ref({
	streamer: false,
})
const headerItems = [
	{
		label: 'Sobre',
		action: () => scrollToElement('about'),
	},
	{
		label: 'Serviço',
		action: () => scrollToElement('service'),
	},
	{
		label: 'Parceiros',
		action: () => scrollToElement('partners'),
	},
	{
		label: 'Quero participar',
		href: '/about',
	},
]
const highlightedStreamer = ref<IUser | null>(null)

highlightedStreamer.value = await useApi('/streamers/highlighted')
streamers.value = await useApi('/streamers?limit=10')
boxes.value = await useApi('/boxes')

function scrollToElement(id: string) {
	const element = document.getElementById(id)

	if (element) {
		element.scrollIntoView({ behavior: 'smooth' })
	}

	if (device.isMobile) {
		isOpenMenu.value = false
	}
}

const validationSchema = z.object({
	twitch: z
		.string()
		.min(4, 'O campo deve ter no mínimo 4 caracteres.')
		.max(25, 'O campo deve ter no máximo 25 caracteres.'),
	phone: z.string().regex(/^\(\d{2}\) \d{5}-\d{4}$/, {
		message: 'O campo deve ser um número de telefone válido.',
	}),
})

const { validate, errors, scrollToError, isValid } = useValidation(validationSchema, streamer, {
	mode: 'eager',
})

const marqueeStreamers = computed(() => {
	const results = streamers.value?.results ?? []

	if (results.length <= 4) {
		return [...results, ...results, ...results, ...results]
	}

	return results
})

const messages = [
	'A galera do chat tá on fire! O Streamers Club arrasou!',
	'Tô amando minha caixa, tudo na vibe perfeita!',
	'Streamers Club manda muito, pprt.',
	'Caneca, camiseta, pôster... Mds, tudo top! 😍',
	'Essa caixa vai gerar treta na live, certeza!',
	'A galera tá pirando nesses presentes!',
	'Mto amor nos detalhes! Cada item é um crush! Amei demais!',
	'Vocês são monstros! A caixa tá muito foda ',
	'PQP que trampo foda, a galera da live vai pirar.',
	'Caixa deu um boost na stream, valeu!',
	'Chat, caixa chegou e foi full insano! mandaram mt!',
]
const generateNewMessage = () => {
	return {
		id: faker.string.uuid(),
		nickname: faker.internet.username(),
		message: messages[Math.floor(Math.random() * messages.length)],
		color: faker.color.rgb({ format: 'hex' }),
	}
}

const onSubmit = async () => {
	if (!(await validate()).success) {
		scrollToError()
		return
	}

	loading.value.streamer = true

	try {
		const makeRequest = async () => {
			try {
				const twitchFormatted = streamer.value.twitch
					.replace('https://www.twitch.tv/', '')
					.replace('https://twitch.tv/', '')

				await $api('/streamers/contact', {
					method: 'POST',
					body: {
						twitch: twitchFormatted,
						phone: streamer.value.phone.replace(/\D/g, ''),
					},
				})
			} catch (error: any) {
				throw new Error(error.data.message)
			}
		}

		toast.promise(makeRequest, {
			loading: 'Enviando sugestão...',
			description: (data: any) => {
				if (!(data instanceof Error)) {
					return 'Assim que o streamer for adicionado, você será notificado por e-mail.'
				}
			},
			success: async () => {
				streamer.value = {
					twitch: '',
					phone: '',
				}

				return 'Streamer sugerido com sucesso!'
			},
			error: (data: any) => {
				return data.message || 'Erro ao sugerir streamer.'
			},
		})
	} catch (error) {
		console.error(error)
	}

	loading.value.streamer = false
}

function sortByLowerPrice(boxes: (IBox & { price: number })[]) {
	return boxes.sort((a, b) => a.price - b.price)
}

const sortedBoxes = computed(() => {
	if (!boxes.value) {
		return []
	}

	return sortByLowerPrice(
		boxes.value.map(box => {
			return {
				...box,
				price: box.prices.find(price => price.currency === 'brl')?.price ?? 0,
			}
		})
	)
})

onBeforeMount(() => {
	const initialMessages = Array.from({ length: 10 }, () => generateNewMessage())

	fakeChatMessages.value.push(...initialMessages)

	interval.value = setInterval(() => {
		fakeChatMessages.value.push(generateNewMessage())

		if (containerRef.value) {
			containerRef.value.scrollTop = containerRef.value.scrollHeight
		}
	}, 2000)
})

onBeforeUnmount(() => {
	if (interval.value) clearInterval(interval.value)
})

useHead({
	title: 'Apoie Seu Streamer Favorito com Caixas Exclusivas',
	meta: [
		{
			hid: 'description',
			name: 'description',
			content:
				'Descubra as caixas misteriosas mais emocionantes criadas pelos seus streamers favoritos! Escolha entre três opções únicas e surpreenda-se com os itens exclusivos.',
		},
	],
})
</script>

<style scoped>
.hide-scrollbar {
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}

@media (min-width: 1024px) {
	.grid-template {
		grid-template-areas: 'col2 col1';
	}

	.col2 {
		grid-area: col2;
	}

	.col1 {
		grid-area: col1;
	}
}
</style>
